const gu = {
  helloGreetingFrom: "હેલો, થી અભિવાદન",
  name: "નામ",
  phoneNumber: "ફોન નંબર",
  address: "સરનામું",
  entername: "તમારું નામ દાખલ કરો",
  enterPhonenumber: "તમારો ફોન નંબર દાખલ કરો",
  enterAddress: "તમારું સરનામું દાખલ કરો",
  requiredName: "નામ જરૂરી છે.",
  requiredPhoneNumber: "ફોન નંબર જરૂરી છે.",
  requiredAddress: "સરનામું જરૂરી છે.",
  submit: "સબમિટ કરો",
  submitting: "સબમિટ કરી રહ્યું છે...",
  Hello: "હેલો",
  TotalPointsEarned: "કુલ મેળવેલા પોઈન્ટ્સ",
  PurchaseHis: "ખરીદીનો ઇતિહાસ",
  Product: "ઉત્પાદન",
  Quantity: "જથ્થો",
};

export default gu;
