const de = {
  helloGreetingFrom: "Hallo, Grüße von",
  name: "Name",
  phoneNumber: "Telefonnummer",
  address: "Adresse",
  entername: "Geben Sie Ihren Namen ein",
  enterPhonenumber: "Geben Sie Ihre Telefonnummer ein",
  enterAddress: "Geben Sie Ihre Adresse ein",
  requiredName: "Der Name ist erforderlich.",
  requiredPhoneNumber: "Die Telefonnummer ist erforderlich.",
  requiredAddress: "Die Adresse ist erforderlich.",
  submit: "Einreichen",
  submitting: "Einreichen...",
  Hello: "Hallo",
  TotalPointsEarned: "Gesamtpunkte verdient",
  PurchaseHis: "Kaufhistorie",
  Product: "Produkt",
  Quantity: "Menge",
};

export default de;
