import ar from "./ar";
import bn from "./bn";
import de from "./de";
import en from "./en";
import es from "./es";
import fr from "./fr";
import gu from "./gu";
import hi from "./hi";
import kn from "./kn";
import ml from "./ml";
import mr from "./mr";
import pt from "./pt";
import ta from "./ta";
import te from "./te";
import th from "./th";
import ur from "./ur";

const locale = {
  en,
  hi,
  ar,
  de,
  bn,
  es,
  fr,
  gu,
  kn,
  ml,
  te,
  pt,
  ta,
  th,
  mr,
  ur,
};

export default locale;
