const bn = {
  helloGreetingFrom: "হ্যালো, এর থেকে শুভেচ্ছা",
  name: "নাম",
  phoneNumber: "ফোন নম্বর",
  address: "ঠিকানা",
  entername: "আপনার নাম লিখুন",
  enterPhonenumber: "আপনার ফোন নম্বর লিখুন",
  enterAddress: "আপনার ঠিকানা লিখুন",
  requiredName: "নাম আবশ্যক।",
  requiredPhoneNumber: "ফোন নম্বর আবশ্যক।",
  requiredAddress: "ঠিকানা আবশ্যক।",
  submit: "জমা দিন",
  submitting: "জমা হচ্ছে...",
  Hello: "হ্যালো",
  TotalPointsEarned: "মোট অর্জিত পয়েন্ট",
  PurchaseHis: "ক্রয়ের ইতিহাস",
  Product: "পণ্য",
  Quantity: "পরিমাণ",
};

export default bn;
