import Home from "./Components/Home/Home";
import { Route, Routes } from "react-router-dom";
import Privacy from "./Pages/Privacy";
import ContactUs from "./Pages/ContactUs";
import CustomerDetails from "./Pages/CustomerDetails";
import NotFound from "./Pages/Not-Found";
import AddCustomer from "./Pages/AddCustomer";

function App() {
  return (
    <div className="">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/customer/:id" element={<CustomerDetails />} />
        <Route
          path="/addCustomer/:id/:businessName"
          element={<AddCustomer />}
        />
        {/* Add the fallback route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
