import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import AppleIcon from "../Images/AppleIcon";
import Playstore from "../Images/playstore.png";
import SimplyLogo from "../Images/SimplyLogo";
import axios from "axios";
import Rewards from "../Images/star.png";
import locale from "../locale";

const CustomerDetails = () => {
  const { id } = useParams();
  const [customerDetails, setCustomerDetails] = useState([]);
  const [rewardPoint, setRewardsPoint] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPoints, setLoadingPoints] = useState(false);

  const getSearch = useParams();
  const [searchParams] = useSearchParams();
  const lang = searchParams.get("lang") || "en";

  const getCustomerDetails = async (customerId) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/business/customer/customerDetail`,
        {
          params: {
            customerId: customerId,
          },
        }
      );
      setLoading(false);

      setCustomerDetails(response?.data?.data);
    } catch (error) {
      console.error("Error fetching customer details:", error);
    }
  };
  const getCustomerRewards = async (customerId) => {
    setLoadingPoints(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/business/customer/customerRewardPoint`,
        {
          params: {
            customerId: customerId,
          },
        }
      );

      setLoadingPoints(false);
      setRewardsPoint(response?.data?.data?.rewardPoint);
    } catch (error) {
      console.error("Error fetching customer details:", error);
    }
  };

  useEffect(() => {
    getCustomerDetails(id);
    getCustomerRewards(id);
  }, [id]);

  return (
    <div className="w-full h-[100vh]">
      <div className=" h-[100%] w-[80%] md:w-[100%]  mx-auto flex flex-col">
        <div className="fixed top-0 bg-white w-full h-[160px] text-start">
          <div className="w-fit md:py-3 py-2 md:px-4 px-6">
            <a href="https://simplystock.co/" rel="noopener noreferrer">
              <SimplyLogo />
            </a>
          </div>
          <div className="mx-4 flex flex-col gap-1">
            <h1 className="tracking-normal flex justify-start gap-1 items-center font-[600] text-[16px]">
              <>
                {locale[lang]?.Hello}{" "}
                {loading ? (
                  <div className="flex items-center space-x-2">
                    <div className="h-3 bg-gray-300 rounded w-32 animate-pulse"></div>
                  </div>
                ) : (
                  <span className="text-[#ff5200] capitalize  my-auto ">
                    {customerDetails?.[0]?.customer?.name}
                  </span>
                )}
              </>
            </h1>
            <div className="flex items-center justify-start gap-2">
              <div className="w-10 h-10">
                <img
                  src={Rewards}
                  className="w-[100%] h-[100%]"
                  alt="rewards-points"
                />
              </div>
              <div className="flex flex-col w-fit gap-[0.2px]">
                {loadingPoints ? (
                  <>
                    <div className="h-6 bg-gray-300 rounded w-16 animate-pulse"></div>
                    <div className="h-4 bg-gray-200 rounded w-32 mt-2 animate-pulse"></div>
                  </>
                ) : (
                  <>
                    <h1 className="font-[600] text-[20px]">
                      {rewardPoint ? rewardPoint : "0"} pts
                    </h1>
                    <h1 className="font-[500] text-gray-500 text-[14px]">
                      {locale[lang]?.TotalPointsEarned}
                    </h1>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className=" mx-4 mt-[160px]">
          <h1 className="font-[600] text-[14px] bg-white">
            {locale[lang]?.PurchaseHis}:{" "}
          </h1>
          <div
            className="overflow-auto"
            style={{ height: "calc(82vh - 160px)" }}
          >
            <table className="min-w-full">
              <thead className="bg-slate-100 shadow-sm text-black sticky top-0">
                <tr>
                  <th className="px-2 py-2 text-[14px] font-normal rounded-l-md  w-[60%]">
                    {locale[lang]?.Product}
                  </th>
                  <th className="px-2 py-2 text-[14px] font-normal rounded-r-md w-[40%]">
                    {locale[lang]?.Quantity}
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading
                  ? // Skeleton Loader
                    Array.from({ length: 3 }).map((_, index) => (
                      <tr key={index} className="border-b text-center">
                        <td className="px-2 py-1">
                          <div className="w-40 h-4 rounded-md mx-auto bg-gray-300 animate-pulse"></div>
                        </td>
                        <td className="px-2 py-1">
                          <div className="w-24 h-4 rounded-md mx-auto bg-gray-300 animate-pulse"></div>
                        </td>
                      </tr>
                    ))
                  : customerDetails[0]?.productDetails?.map((product) => (
                      <tr key={product.sku} className={`border-b text-center`}>
                        <td className={`px-2  py-1`}>
                          <div className="flex flex-row justify-center items-center">
                            <div className="w-[40%] text-end">
                              {product?.imageUrl ? (
                                <img
                                  src={product?.imageUrl}
                                  className="h-12 w-12 object-cover ml-auto rounded-sm "
                                  alt=""
                                />
                              ) : (
                                <div className="h-12 w-12 bg-gray-200 ml-auto rounded-sm"></div>
                              )}
                            </div>
                            <div className="w-[60%] text-start mx-2">
                              {product?.name}
                            </div>
                          </div>
                        </td>
                        <td className="px-2 py-1">{product?.amount}</td>
                      </tr>
                    ))}
              </tbody>
              <tbody>
                {loading
                  ? // Skeleton Loader
                    Array.from({ length: 3 }).map((_, index) => (
                      <tr key={index} className="border-b text-center">
                        <td className="px-2 py-1">
                          <div className="w-40 h-4 rounded-md mx-auto bg-gray-300 animate-pulse"></div>
                        </td>
                        <td className="px-2 py-1">
                          <div className="w-24 h-4 rounded-md mx-auto bg-gray-300 animate-pulse"></div>
                        </td>
                      </tr>
                    ))
                  : customerDetails[0]?.productDetails?.map((product) => (
                      <tr key={product.sku} className={`border-b text-center`}>
                        <td className={`px-2  py-1`}>
                          <div className="flex flex-row justify-center items-center">
                            <div className="w-[40%] text-end">
                              {product?.imageUrl ? (
                                <img
                                  src={product?.imageUrl}
                                  className="h-12 w-12 object-cover ml-auto rounded-sm "
                                  alt=""
                                />
                              ) : (
                                <div className="h-12 w-12 bg-gray-200 ml-auto rounded-sm"></div>
                              )}
                            </div>
                            <div className="w-[60%] text-start mx-2">
                              {product?.name}
                            </div>
                          </div>
                        </td>
                        <td className="px-2 py-1">{product?.amount}</td>
                      </tr>
                    ))}
              </tbody>
              <tbody>
                {loading
                  ? // Skeleton Loader
                    Array.from({ length: 3 }).map((_, index) => (
                      <tr key={index} className="border-b text-center">
                        <td className="px-2 py-1">
                          <div className="w-40 h-4 rounded-md mx-auto bg-gray-300 animate-pulse"></div>
                        </td>
                        <td className="px-2 py-1">
                          <div className="w-24 h-4 rounded-md mx-auto bg-gray-300 animate-pulse"></div>
                        </td>
                      </tr>
                    ))
                  : customerDetails[0]?.productDetails?.map((product) => (
                      <tr key={product.sku} className={`border-b text-center`}>
                        <td className={`px-2  py-1`}>
                          <div className="flex flex-row justify-center items-center">
                            <div className="w-[40%] text-end">
                              {product?.imageUrl ? (
                                <img
                                  src={product?.imageUrl}
                                  className="h-12 w-12 object-cover ml-auto rounded-sm "
                                  alt=""
                                />
                              ) : (
                                <div className="h-12 w-12 bg-gray-200 ml-auto rounded-sm"></div>
                              )}
                            </div>
                            <div className="w-[60%] text-start mx-2">
                              {product?.name}
                            </div>
                          </div>
                        </td>
                        <td className="px-2 py-1">{product?.amount}</td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* </div> */}
      </div>
      <div className="gap-3 flex-row-reverse md:flex-row md:w-[100%] md:justify-center md:items-center flex  justify-center w-[80%] absolute bottom-3 left-1/2 transform -translate-x-1/2 ">
        <button
          className="bg-[#203A4F] w-[130px] h-[38px] flex flex-row justify-center  text-white rounded-md"
          onClick={() =>
            window.open(
              "https://apps.apple.com/us/app/simply-simple-stock-inventory/id6737231476",
              "_blank"
            )
          }
        >
          <div className="my-auto flex items-center gap-2 font-normal">
            <div>
              <AppleIcon />
            </div>
            <div className="text-start leading-[12px]">
              <h1 className="text-[8px]">Download on the</h1>
              <h1 className="capitalize text-[15px] pb-1">App Store</h1>
            </div>
          </div>
        </button>

        <button
          className="bg-[#203A4F] w-[130px] h-[38px] flex flex-row justify-center text-white rounded-md"
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=com.bhairavasoft.simply",
              "_blank"
            )
          }
        >
          <div className="my-auto flex items-center gap-[5px] font-normal">
            <div>
              <img src={Playstore} className="w-[20px] h-[20px]" alt="" />
            </div>
            <div className="text-start leading-[12px]">
              <h1 className="text-[8px] uppercase">Get it on</h1>
              <h1 className="capitalize text-[13px]">Google Play</h1>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default CustomerDetails;
