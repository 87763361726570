const hi = {
  helloGreetingFrom: "नमस्ते, से अभिवादन",
  name: "नाम",
  phoneNumber: "फ़ोन नंबर",
  address: "पता",
  entername: "अपना नाम दर्ज करें",
  enterPhonenumber: "अपना फ़ोन नंबर दर्ज करें",
  enterAddress: "अपना पता दर्ज करें",
  requiredName: "नाम आवश्यक है।",
  requiredPhoneNumber: "फ़ोन नंबर आवश्यक है।",
  requiredAddress: "पता आवश्यक है।",
  submit: "जमा करें",
  submitting: "जमा किया जा रहा है...",
  Hello: "नमस्ते",
  TotalPointsEarned: "कुल अर्जित अंक",
  PurchaseHis: "खरीद इतिहास",
  Product: "उत्पाद",
  Quantity: "मात्रा",
};
export default hi;
