const mr = {
  helloGreetingFrom: "नमस्कार, कडून अभिवादन",
  name: "नाव",
  phoneNumber: "फोन नंबर",
  address: "पत्ता",
  entername: "तुमचे नाव टाका",
  enterPhonenumber: "तुमचा फोन नंबर टाका",
  enterAddress: "तुमचा पत्ता टाका",
  requiredName: "नाव आवश्यक आहे.",
  requiredPhoneNumber: "फोन नंबर आवश्यक आहे.",
  requiredAddress: "पत्ता आवश्यक आहे.",
  submit: "सबमिट करा",
  submitting: "सबमिट करत आहे...",
  Hello: "नमस्कार",
  TotalPointsEarned: "कमावलेले एकूण गुण",
  PurchaseHis: "खरेदीचा इतिहास",
  Product: "उत्पादन",
  Quantity: "प्रमाण",
};

export default mr;
