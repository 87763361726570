import React from "react";
import SimplyLogoTest from "../../Images/SimplyLogoTest";
import AppleIcon from "../../Images/AppleIcon";
import DesktopIcon from "../../Images/DesktopIcon";
import Playstore from "../../Images/playstore.png";

import "./Testimonials.css";

const Testimonials = () => {
  return (
    <div className="md:py-8 py-24 px-[40px]">
      <h1 className="font-[700] text-[36px] flex flex-row items-center justify-center  mb-6 text-center">
        Why Users Love{" "}
        <p className="ml-[9px] mt-[12px]">
          <SimplyLogoTest />
        </p>
      </h1>
      <p className="font-[400] text-[16px] md:text-[16px] sm:text-[14px] mb-12 text-center">
        From inventory management to sales tracking, see why Simply is their
        go-to.
      </p>
      <div className="flex md:flex-col md:gap-4 gap-4 flex-row justify-between items-stretch my-10">
        <div className="flex-1 md:w-[100%] px-10 py-10 rounded-2xl border-2 bg-[#F5F5F5]">
          <p className="font-[400] relative text-[16px] md:text-[16px] sm:text-[14px] text-start px-10">
            <p className=" -top-[25px] left-2   absolute text-[60px]">“</p>
            Simply makes managing my store's inventory and sales a breeze. I can
            track daily, weekly, and monthly reports easily. It’s user-friendly
            and has saved me both time and money.
          </p>
          <hr className="my-4" />
          <div className="px-10">
            <h1 className="font-[500] text-[16px]">Sonia</h1>
            <p className="text-[#11111199] text-[12px]">
              Online Clothing Store Owner
            </p>
          </div>
        </div>
        <div className="flex-1 md:w-[100%] px-10 py-10 rounded-2xl border-2 bg-[#F5F5F5]">
          <p className="font-[400] relative text-[16px] md:text-[16px] sm:text-[14px] text-start px-10">
            <p className=" -top-[25px] left-0   absolute text-[60px]">“</p>
            I'm impressed by how complete and advanced the Simply app is. It
            helps me manage staff, finances, and transactions with ease. Plus,
            the barcode scanner feature is invaluable for stock checks!
          </p>
          <hr className="my-4" />
          <div className="px-10">
            <h1 className="font-[500] text-[16px]">Kamal</h1>
            <p className="text-[#11111199] text-[12px]">Shoe Manufacturer</p>
          </div>
        </div>
      </div>

      <div className="button-for-ios-android-boost-test flex w-[600px] sm:w-[100%] mx-auto flex-row md:flex-row sm:flex-wrap justify-center items-center">
        {/* <button
          className="button-ios-boost-test rounded-lg py-2.5 w-[50%] md:w-[60%] "
          onClick={() =>
            window.open(
              "https://apps.apple.com/us/app/simply-simple-stock-inventory/id6737231476",
              "_blank"
            )
          }
        >
          <AppleIcon /> Download for iOS
        </button> */}

        <button
          className="bg-[#203A4F] w-[100%]  h-[40px] flex flex-row justify-center  text-white rounded-md"
          onClick={() =>
            window.open(
              "https://apps.apple.com/us/app/simply-simple-stock-inventory/id6737231476",
              "_blank"
            )
          }
        >
          <div className="my-auto flex items-center gap-2 font-normal">
            <div>
              <AppleIcon />
            </div>
            <div className="text-start leading-[12px]">
              <h1 className="text-[8px]">Download on the</h1>
              <h1 className="capitalize text-[15px] pb-1">App Store</h1>
            </div>
          </div>
        </button>

        {/* <button
          className="button-android-boost-test  rounded-lg py-2.5 w-[50%] md:w-[60%]"
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=com.bhairavasoft.simply",
              "_blank"
            )
          }
        >
          <AndroidIcon /> Download for Android
        </button> */}

        <button
          className="bg-[#203A4F] w-[100%] h-[38px] flex flex-row justify-center text-white rounded-md"
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=com.bhairavasoft.simply",
              "_blank"
            )
          }
        >
          <div className="my-auto flex items-center gap-[5px] font-normal">
            <div>
              <img src={Playstore} className="w-[20px] h-[20px]" alt="" />
            </div>
            <div className="text-start leading-[12px]">
              <h1 className="text-[8px] uppercase">Get it on</h1>
              <h1 className="capitalize text-[13px]">Google Play</h1>
            </div>
          </div>
        </button>
      </div>
      <div className="start-using-desktop-boost-test w-[600px] sm:w-[100%] mx-auto">
        <button
          onClick={() =>
            window.open("https://web.simplystock.co/login", "_blank")
          }
          className="text-[#FF5200] w-[100%] font-[600] border-[1px] rounded-lg border-[#FF5200] py-2.5 flex justify-center items-center gap-2"
        >
          <DesktopIcon /> Start Using on Desktop
        </button>
      </div>
    </div>
  );
};

export default Testimonials;
