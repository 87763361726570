// NotFound.js
import React from "react";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen p-4">
      <h1 className="text-4xl md:text-5xl font-semibold text-center mb-4">
        404 - Not Found
      </h1>
      <p className="text-lg md:text-xl mb-6 text-center">
        The page you are looking for does not exist.
      </p>
      {/* <Link
        to="/"
        className="px-6 py-3 text-lg font-semibold text-white bg-blue-500 hover:bg-blue-600 rounded-md transition duration-200"
      >
        Go to Home
      </Link> */}
    </div>
  );
};

export default NotFound;
