import React from "react";
import AppleIcon from "../../Images/AppleIcon";
import DesktopIcon from "../../Images/DesktopIcon";
import ZigZagImage from "../../Images/ZigZagImage";
import DashboardImage from "../../Images/DashboardImage";
import "./Home.css";
import Management from "../Management/Management";
import DesignedForPage from "../DesignedForPage/DesignedForPage";
import Navbar from "../Navbar/Navbar";
import BoostGrowth from "../BoostGrowth/BoostGrowth";
import SupplyChain from "../SupplyChain/SupplyChain";
import EveryIndustry from "../EveryIndustry/EveryIndustry";
import Footer from "../Footer/Footer";
import Testimonials from "../Testimonials/Testimonials";
import Playstore from "../../Images/playstore.png";

const Home = () => {
  console.log("work");

  return (
    <>
      <Navbar />
      <div
        className="h-screen rounded-lg bg-[linear-gradient(to_bottom,rgba(255,216,198,1),rgba(255,241,234,1))] mainContainer"
        style={{ height: "calc(100vh - 80px)" }}
      >
        <div className="container-one">
          <h1 className="text-header leading-normal">
            Effortless Inventory <br /> Management for <br />
            <span className="text-[#FF5200]">Growing Businesses</span>
          </h1>
          <div className="zigzag-image">
            <ZigZagImage />
          </div>
          <p className="paragraph-main">
            Streamline your operations with a simple,
            <br /> powerful inventory solution.
          </p>

          <div className="download-icon-layout w-full gap-4 mt-5">
            <button
              className="bg-[#203A4F] max-w-[230px] w-[100%] h-[40px] flex flex-row justify-center text-white rounded-md"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.bhairavasoft.simply",
                  "_blank"
                )
              }
            >
              <div className="my-auto flex items-center gap-[5px] font-normal">
                <div>
                  <img src={Playstore} className="w-[20px] h-[20px]" alt="" />
                </div>
                <div className="text-start leading-[12px]">
                  <h1 className="text-[8px] uppercase">Get it on</h1>
                  <h1 className="capitalize text-[13px]">Google Play</h1>
                </div>
              </div>
            </button>

            <button
              className="bg-[#203A4F] max-w-[230px] w-[100%] h-[40px] flex flex-row justify-center  text-white rounded-md"
              onClick={() =>
                window.open(
                  "https://apps.apple.com/us/app/simply-simple-stock-inventory/id6737231476",
                  "_blank"
                )
              }
            >
              <div className="my-auto flex items-center gap-2 font-normal">
                <div>
                  <AppleIcon />
                </div>
                <div className="text-start leading-[12px]">
                  <h1 className="text-[8px]">Download on the</h1>
                  <h1 className="capitalize text-[15px] pb-1">App Store</h1>
                </div>
              </div>
            </button>
            {/* <button
              className="button-ios rounded-lg py-2.5"
              onClick={() =>
                window.open(
                  "https://apps.apple.com/us/app/simply-simple-stock-inventory/id6737231476",
                  "_blank"
                )
              }
            >
              <AppleIcon /> Download for iOS
            </button>

            <button
              className="button-android rounded-lg py-2.5"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.bhairavasoft.simply",
                  "_blank"
                )
              }
            >
              <AndroidIcon /> Download for Android
            </button> */}
          </div>

          <div className="start-using-desktop mt-6">
            <button
              onClick={() =>
                window.open("https://web.simplystock.co/login", "_blank")
              }
              className="text-[#FF5200] max-w-[480px] w-[100%] font-[600] border-[1px] rounded-lg border-[#FF5200] py-2.5 flex justify-center items-center gap-2"
            >
              <DesktopIcon /> Start Using on Desktop
            </button>
          </div>
        </div>

        <div className="container-two w-[60%] flex justify-end items-center">
          <DashboardImage />
        </div>
      </div>

      <Management />
      <DesignedForPage />
      <BoostGrowth />
      <SupplyChain />
      <EveryIndustry />
      <Testimonials />
      <Footer />
    </>
  );
};

export default Home;
