const ar = {
  helloGreetingFrom: "مرحبًا، تحيات من",
  name: "الاسم",
  phoneNumber: "رقم الهاتف",
  address: "العنوان",
  entername: "أدخل اسمك",
  enterPhonenumber: "أدخل رقم هاتفك",
  enterAddress: "أدخل عنوانك",
  requiredName: "الاسم مطلوب.",
  requiredPhoneNumber: "رقم الهاتف مطلوب.",
  requiredAddress: "العنوان مطلوب.",
  submit: "إرسال",
  submitting: "جارٍ الإرسال...",
  Hello: "مرحبًا",
  TotalPointsEarned: "إجمالي النقاط المكتسبة",
  PurchaseHis: "تاريخ الشراء",
  Product: "المنتج",
  Quantity: "الكمية",
};
export default ar;
