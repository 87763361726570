const en = {
  helloGreetingFrom: "Hello, greetings from",
  name: "Name",
  phoneNumber: "Phone Number",
  address: "Address",
  entername: "Enter your name",
  enterPhonenumber: "Enter your phone number",
  enterAddress: "Enter your address",
  requiredName: "Name is required.",
  requiredPhoneNumber: "Phone number is required.",
  requiredAddress: "Address is required.",
  submit: "Submit",
  submitting: "Submitting...",
  Hello: "Hello",
  TotalPointsEarned: "Total points earned",
  PurchaseHis: "Purchase History",
  Product: "Product",
  Quantity: "Quantity",
};
export default en;
