const th = {
  helloGreetingFrom: "สวัสดี, คำทักทายจาก",
  name: "ชื่อ",
  phoneNumber: "หมายเลขโทรศัพท์",
  address: "ที่อยู่",
  entername: "กรุณากรอกชื่อของคุณ",
  enterPhonenumber: "กรุณากรอกหมายเลขโทรศัพท์ของคุณ",
  enterAddress: "กรุณากรอกที่อยู่ของคุณ",
  requiredName: "จำเป็นต้องระบุชื่อ",
  requiredPhoneNumber: "จำเป็นต้องระบุหมายเลขโทรศัพท์",
  requiredAddress: "จำเป็นต้องระบุที่อยู่",
  submit: "ส่ง",
  submitting: "กำลังส่ง...",
  Hello: "สวัสดี",
  TotalPointsEarned: "คะแนนทั้งหมดที่ได้รับ",
  PurchaseHis: "ประวัติการซื้อ",
  Product: "สินค้า",
  Quantity: "จำนวน",
};

export default th;
