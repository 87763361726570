const kn = {
  helloGreetingFrom: "ನಮಸ್ಕಾರ, ನಿಂದ ಶುಭಾಶಯಗಳು",
  name: "ಹೆಸರು",
  phoneNumber: "ದೂರವಾಣಿ ಸಂಖ್ಯೆ",
  address: "ವಿಳಾಸ",
  entername: "ನಿಮ್ಮ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
  enterPhonenumber: "ನಿಮ್ಮ ದೂರವಾಣಿ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
  enterAddress: "ನಿಮ್ಮ ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ",
  requiredName: "ಹೆಸರು ಅಗತ್ಯವಿದೆ.",
  requiredPhoneNumber: "ದೂರವಾಣಿ ಸಂಖ್ಯೆ ಅಗತ್ಯವಿದೆ.",
  requiredAddress: "ವಿಳಾಸ ಅಗತ್ಯವಿದೆ.",
  submit: "ಸಲ್ಲಿಸು",
  submitting: "ಸಲ್ಲಿಸಲಾಗುತ್ತಿದೆ...",
  Hello: "ಹಲೋ",
  TotalPointsEarned: "ಒಟ್ಟು ಸಂಪಾದಿಸಿದ ಅಂಕಗಳು",
  PurchaseHis: "ಖರೀದಿಯ ಇತಿಹಾಸ",
  Product: "ಉತ್ಪನ್ನ",
  Quantity: "ಪ್ರಮಾಣ",
};
export default kn;
