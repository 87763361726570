const fr = {
  helloGreetingFrom: "Bonjour, salutations de",
  name: "Nom",
  phoneNumber: "Numéro de téléphone",
  address: "Adresse",
  entername: "Entrez votre nom",
  enterPhonenumber: "Entrez votre numéro de téléphone",
  enterAddress: "Entrez votre adresse",
  requiredName: "Le nom est obligatoire.",
  requiredPhoneNumber: "Le numéro de téléphone est obligatoire.",
  requiredAddress: "L'adresse est obligatoire.",
  submit: "Soumettre",
  submitting: "Soumission...",
  Hello: "Bonjour",
  TotalPointsEarned: "Total des points gagnés",
  PurchaseHis: "Historique des achats",
  Product: "Produit",
  Quantity: "Quantité",
};
export default fr;
