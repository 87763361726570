import React from 'react'
import AppleIcon from "../../Images/AppleIcon";
import Playstore from "../../Images/playstore.png";
const DownloadApp = () => {
  return (
    <div className="gap-3 flex-row-reverse md:flex-row md:w-[100%] md:justify-center md:items-center flex  justify-center w-[80%] absolute bottom-3 left-1/2 transform -translate-x-1/2 ">
    <button
      className="bg-[#203A4F] w-[130px] h-[38px] flex flex-row justify-center  text-white rounded-md"
      onClick={() =>
        window.open(
          "https://apps.apple.com/us/app/simply-simple-stock-inventory/id6737231476",
          "_blank"
        )
      }
    >
      <div className="my-auto flex items-center gap-2 font-normal">
        <div>
          <AppleIcon />
        </div>
        <div className="text-start leading-[12px]">
          <h1 className="text-[8px]">Download on the</h1>
          <h1 className="capitalize text-[15px] pb-1">App Store</h1>
        </div>
      </div>
    </button>

    <button
      className="bg-[#203A4F] w-[130px] h-[38px] flex flex-row justify-center text-white rounded-md"
      onClick={() =>
        window.open(
          "https://play.google.com/store/apps/details?id=com.bhairavasoft.simply",
          "_blank"
        )
      }
    >
      <div className="my-auto flex items-center gap-[5px] font-normal">
        <div>
          <img src={Playstore} className="w-[20px] h-[20px]" alt="" />
        </div>
        <div className="text-start leading-[12px]">
          <h1 className="text-[8px] uppercase">Get it on</h1>
          <h1 className="capitalize text-[13px]">Google Play</h1>
        </div>
      </div>
    </button>
  </div>
  )
}

export default DownloadApp