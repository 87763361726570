const ta = {
  helloGreetingFrom: "வணக்கம், இலிருந்து வாழ்த்துகள்",
  name: "பெயர்",
  phoneNumber: "தொலைபேசி எண்",
  address: "முகவரி",
  entername: "உங்கள் பெயரை உள்ளிடவும்",
  enterPhonenumber: "உங்கள் தொலைபேசி எண்ணை உள்ளிடவும்",
  enterAddress: "உங்கள் முகவரியை உள்ளிடவும்",
  requiredName: "பெயர் தேவை.",
  requiredPhoneNumber: "தொலைபேசி எண் தேவை.",
  requiredAddress: "முகவரி தேவை.",
  submit: "சமர்ப்பிக்கவும்",
  submitting: "சமர்ப்பித்து கொண்டிருக்கிறது...",
  Hello: "வணக்கம்",
  TotalPointsEarned: "மொத்தம் பெற்ற புள்ளிகள்",
  PurchaseHis: "கொள்முதல் வரலாறு",
  Product: "தயாரிப்பு",
  Quantity: "அளவு",
};

export default ta;
