const es = {
  helloGreetingFrom: "Hola, saludos de",
  name: "Nombre",
  phoneNumber: "Número de teléfono",
  address: "Dirección",
  entername: "Ingrese su nombre",
  enterPhonenumber: "Ingrese su número de teléfono",
  enterAddress: "Ingrese su dirección",
  requiredName: "El nombre es obligatorio.",
  requiredPhoneNumber: "El número de teléfono es obligatorio.",
  requiredAddress: "La dirección es obligatoria.",
  submit: "Enviar",
  submitting: "Enviando...",
  Hello: "Hola",
  TotalPointsEarned: "Puntos totales ganados",
  PurchaseHis: "Historial de compras",
  Product: "Producto",
  Quantity: "Cantidad",
};

export default es;
