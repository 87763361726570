import React, { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import DownloadApp from "../Components/DownloadApp/DownloadApp";
import SimplyLogo from "../Images/SimplyLogo";
import axios from "axios";

import Snackbar from "@mui/joy/Snackbar";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button } from "@mui/joy";
import locale from "../locale";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const AddCustomer = () => {
  const getSearch = useParams();
  const [searchParams] = useSearchParams();
  const lang = searchParams.get("lang") || "en";
  const isoCode = searchParams.get("isoCode")?.toLocaleLowerCase() || "in";

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState("");

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    address: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    phoneNumber: "",
    address: "",
  });
  console.log(formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear the error for the field when the user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handlePhoneChange = (phone) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phoneNumber: phone,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      phoneNumber: "",
    }));
  };

  const validateForm = () => {
    const { name, phoneNumber, address } = formData;
    const newErrors = {};

    // Validate name
    if (!name.trim()) {
      newErrors.name = `${locale[lang]?.requiredName}`;
    }

    // Validate phone number (must be a number)
    if (!phoneNumber.trim()) {
      newErrors.phoneNumber = `${locale[lang]?.requiredPhoneNumber}`;
    } else if (isNaN(phoneNumber)) {
      newErrors.phoneNumber = "Phone number must be a valid number.";
    }

    // Validate address
    if (!address.trim()) {
      newErrors.address = `${locale[lang]?.requiredAddress}`;
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const dataToSubmit = {
        name: formData.name,
        contact: `+${formData.phoneNumber}`,
        address: formData.address,
      };

      setLoading(true);
      try {
        const response = await axios.post(
          `${
            process.env.REACT_APP_API_URL
          }/api/business/customer/createCustomer?businessId=${Number(
            getSearch.id
          )}`,
          dataToSubmit
        );
        setLoading(false);
        if (response.status === 200) {
          setResponseStatus("ok");
          setOpenSnackbar(true);
          setSuccessMessage(
            response?.data?.data?.message
              ? response?.data?.data?.message
              : "Customer created successfully"
          );
          setFormData({
            name: "",
            phoneNumber: "",
            address: "",
          });
          setErrors({
            name: "",
            phoneNumber: "",
            address: "",
          });
        }
      } catch (error) {
        setLoading(false);
        setResponseStatus("");
        setOpenSnackbar(true);
        setSuccessMessage(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Something Went Wrong"
        );
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className="min-h-screen">
      <Snackbar
        variant="soft"
        color={responseStatus === "ok" ? "success" : "danger"}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={4000}
        startDecorator={
          responseStatus !== "ok" ? (
            <CancelIcon />
          ) : (
            <PlaylistAddCheckCircleRoundedIcon />
          )
        }
        endDecorator={
          <Button
            onClick={() => setOpenSnackbar(false)}
            size="sm"
            variant="soft"
            color={responseStatus === "ok" ? "success" : "danger"}
          >
            Dismiss
          </Button>
        }
      >
        {successMessage}
      </Snackbar>
      <div className="w-fit md:py-3 py-2 md:px-4 px-6">
        <a href="https://simplystock.co/" rel="noopener noreferrer">
          <SimplyLogo />
        </a>
      </div>
      <div className="max-w-6xl md:px-4 px-6 md:py-4 py-6 w-[100%] mx-auto">
        <h1 className="text-[18px] font-[600] tracking-wide">
          {locale[lang]?.helloGreetingFrom}{" "}
          <span className="text-[#ff5200] capitalize my-auto animate-fadeIn">
            {getSearch?.businessName?.charAt(0)?.toUpperCase() +
              getSearch?.businessName?.slice(1)}
          </span>
        </h1>
        <div className="max-w-md mx-auto p-6 bg-white shadow-lg mt-8 rounded-lg">
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <div className="">
              <label
                className="block text-gray-700 placeholder:text-[16px] font-bold mb-1"
                htmlFor="name"
              >
                {locale[lang]?.name}
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 placeholder:text-[16px] rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder={locale[lang]?.entername}
              />
              {errors.name && (
                <p className="text-red-500 text-xs mt-1.5">{errors.name}</p>
              )}
            </div>

            <div className="">
              <label
                className="block text-gray-700 placeholder:text-[16px] font-bold mb-1"
                htmlFor="phoneNumber"
              >
                {locale[lang]?.phoneNumber}
              </label>
              <PhoneInput
                country={isoCode}
                enableSearch={true}
                value={formData.phoneNumber}
                onChange={handlePhoneChange}
                inputStyle={{
                  height: "35px",
                  width: "100%",
                  outline: "none",
                }}
                buttonStyle={{
                  height: "35px",
                }}
              />
              {errors.phoneNumber && (
                <p className="text-red-500 text-xs mt-1.5">
                  {errors.phoneNumber}
                </p>
              )}
            </div>

            <div className="">
              <label
                className="block text-gray-700 placeholder:text-[16px] font-bold mb-1"
                htmlFor="address"
              >
                {locale[lang]?.address}
              </label>
              <textarea
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 placeholder:text-[16px]"
                placeholder={locale[lang]?.enterAddress}
              ></textarea>
              {errors.address && (
                <p className="text-red-500 text-xs mt-0.5">{errors.address}</p>
              )}
            </div>

            <button
              disabled={loading}
              type="submit"
              className={`w-full py-2 bg-[#FF5200] text-white font-bold disabled:bg-gray-300 rounded-md hover:bg-[#FF6900] transition-all duration-300 ease-in-out focus:outline-none ${
                loading ? "cursor-not-allowed" : ""
              }`}
            >
              {loading
                ? `${locale[lang]?.submitting}`
                : `${locale[lang]?.submit}`}
            </button>
          </form>
        </div>
      </div>

      <DownloadApp />
    </div>
  );
};

export default AddCustomer;
