const te = {
  helloGreetingFrom: "హలో, నుండి శుభాకాంక్షలు",
  name: "పేరు",
  phoneNumber: "ఫోన్ నంబర్",
  address: "చిరునామా",
  entername: "మీ పేరు నమోదు చేయండి",
  enterPhonenumber: "మీ ఫోన్ నంబర్ నమోదు చేయండి",
  enterAddress: "మీ చిరునామా నమోదు చేయండి",
  requiredName: "పేరు అవసరం.",
  requiredPhoneNumber: "ఫోన్ నంబర్ అవసరం.",
  requiredAddress: "చిరునామా అవసరం.",
  submit: "సబ్మిట్ చేయండి",
  submitting: "సబ్మిట్ చేస్తున్నాం...",
  Hello: "హలో",
  TotalPointsEarned: "మొత్తం పొందిన పాయింట్లు",
  PurchaseHis: "కొనుగోలు చరిత్ర",
  Product: "ఉత్పత్తి",
  Quantity: "పరిమాణం",
};

export default te;
