import React from "react";
import BoostGrowthImage from "../../Images/BoostGrowthImage";
import AppleIcon from "../../Images/AppleIcon";
import Playstore from "../../Images/playstore.png";

import DesktopIcon from "../../Images/DesktopIcon";
import "./BoostGrowth.css";

const BoostGrowth = () => {
  return (
    <div className="md:py-8 py-24 px-[40px] bg-[#FF52001A]">
      <div className="flex flex-row md:flex-col  md:justify-center md:gap-5 justify-between">
        <div className="md:w-[100%] w-[40%] flex justify-center items-center">
          <BoostGrowthImage />
        </div>
        <div className="md:w-[100%]  w-[50%]  flex flex-col justify-center items-start">
          <h1 className="font-[700] mt-6 w-full md:text-[40px] sm:text-[36px] md:text-center text-[44px] text-black">
            Boost Your Business Growth
          </h1>
          <p className="text-[16px] mt-5 w-full  md:text-center">
            Optimize stock control, reduce overheads, and focus on business
            expansion. Simply helps streamline operations, freeing up time to
            grow your business.
          </p>
          <div className="button-for-ios-android-boost flex flex-row md:flex-row sm:flex-wrap justify-center items-center">
            {/* <button
              className="button-ios-boost rounded-lg py-2.5 w-[50%] md:w-[60%] "
              onClick={() =>
                window.open(
                  "https://apps.apple.com/us/app/simply-simple-stock-inventory/id6737231476",
                  "_blank"
                )
              }
            >
              <AppleIcon /> Download for iOS
            </button> */}
            <button
              className="bg-[#203A4F] w-[100%]  h-[40px] flex flex-row justify-center  text-white rounded-md"
              onClick={() =>
                window.open(
                  "https://apps.apple.com/us/app/simply-simple-stock-inventory/id6737231476",
                  "_blank"
                )
              }
            >
              <div className="my-auto flex items-center gap-2 font-normal">
                <div>
                  <AppleIcon />
                </div>
                <div className="text-start leading-[12px]">
                  <h1 className="text-[8px]">Download on the</h1>
                  <h1 className="capitalize text-[15px] pb-1">App Store</h1>
                </div>
              </div>
            </button>
            {/* <button
              className="button-android-boost rounded-lg py-2.5 w-[50%] md:w-[60%]"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.bhairavasoft.simply",
                  "_blank"
                )
              }
            >
              <AndroidIcon /> Download for Android
            </button> */}
            <button
              className="bg-[#203A4F] w-[100%] h-[38px] flex flex-row justify-center text-white rounded-md"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.bhairavasoft.simply",
                  "_blank"
                )
              }
            >
              <div className="my-auto flex items-center gap-[5px] font-normal">
                <div>
                  <img src={Playstore} className="w-[20px] h-[20px]" alt="" />
                </div>
                <div className="text-start leading-[12px]">
                  <h1 className="text-[8px] uppercase">Get it on</h1>
                  <h1 className="capitalize text-[13px]">Google Play</h1>
                </div>
              </div>
            </button>
          </div>

          <div className="start-using-desktop-boost">
            <button
              onClick={() =>
                window.open("https://web.simplystock.co/login", "_blank")
              }
              className="text-[#FF5200] w-[100%] font-[600] border-[1px] rounded-lg border-[#FF5200] py-2.5 flex justify-center items-center gap-2"
            >
              <DesktopIcon /> Start Using on Desktop
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoostGrowth;
