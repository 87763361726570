const ml = {
  helloGreetingFrom: "ഹലോ, നിന്ന് ഗ്രീറ്റിംഗ്സ്",
  name: "പേര്",
  phoneNumber: "ഫോൺ നമ്പർ",
  address: "വിലാസം",
  entername: "നിങ്ങളുടെ പേര് നൽകുക",
  enterPhonenumber: "നിങ്ങളുടെ ഫോൺ നമ്പർ നൽകുക",
  enterAddress: "നിങ്ങളുടെ വിലാസം നൽകുക",
  requiredName: "പേര് ആവശ്യമാണ്.",
  requiredPhoneNumber: "ഫോൺ നമ്പർ ആവശ്യമാണ്.",
  requiredAddress: "വിലാസം ആവശ്യമാണ്.",
  submit: "സമർപ്പിക്കുക",
  submitting: "സമർപ്പിക്കുന്നു...",
  Hello: "ഹലോ",
  TotalPointsEarned: "ആകെ നേടിയത് പോയിന്റുകൾ",
  PurchaseHis: "വാങ്ങലിന്റെ ചരിത്രം",
  Product: "ഉൽപ്പന്നം",
  Quantity: "അളവ്",
};

export default ml;
