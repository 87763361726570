const ur = {
  helloGreetingFrom: "ہیلو، کی جانب سے سلام",
  name: "نام",
  phoneNumber: "فون نمبر",
  address: "پتہ",
  entername: "اپنا نام درج کریں",
  enterPhonenumber: "اپنا فون نمبر درج کریں",
  enterAddress: "اپنا پتہ درج کریں",
  requiredName: "نام ضروری ہے۔",
  requiredPhoneNumber: "فون نمبر ضروری ہے۔",
  requiredAddress: "پتہ ضروری ہے۔",
  submit: "جمع کریں",
  submitting: "جمع کیا جا رہا ہے...",
  Hello: "ہیلو",
  TotalPointsEarned: "کل کمائے گئے پوائنٹس",
  PurchaseHis: "خریداری کی تاریخ",
  Product: "مصنوعات",
  Quantity: "مقدار",
};

export default ur;
