const pt = {
  helloGreetingFrom: "Olá, saudação de",
  name: "Nome",
  phoneNumber: "Número de telefone",
  address: "Endereço",
  entername: "Digite seu nome",
  enterPhonenumber: "Digite seu número de telefone",
  enterAddress: "Digite seu endereço",
  requiredName: "O nome é obrigatório.",
  requiredPhoneNumber: "O número de telefone é obrigatório.",
  requiredAddress: "O endereço é obrigatório.",
  submit: "Enviar",
  submitting: "Enviando...",
  Hello: "Olá",
  TotalPointsEarned: "Total de pontos ganhos",
  PurchaseHis: "Histórico de compras",
  Product: "Produto",
  Quantity: "Quantidade",
};
export default pt;
